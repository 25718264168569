<template>
  <div id="benchmarks-container">
    <h2><i class="fad fa-fw fa-telescope"></i> Competition Benchmark</h2>
    <p class="lead">
      Track your Competition in regards to
      <router-link to="/keywords">Keywords</router-link>
      ,
      <router-link to="/channels">Channels</router-link>
      and
      <router-link to="/contents">Content</router-link>
      . From there you can generate
      <router-link to="/findings">Findings</router-link>
      and
      <router-link to="/ideas">Ideas</router-link>
      .
    </p>

    <ComingSoonBanner></ComingSoonBanner>

    <!--<p>Todo:</p>
    <ul>
      <li>Build Google Analytics and Google Ads integration</li>
      <li>Build Charts</li>
      <li>Connect to <router-link to="/journeys">Customer Journeys</router-link></li>
      <li>Connect to <router-link to="/contents">Content Library</router-link></li>
      <li>Connect to <router-link to="/keywords">Keyword Planner</router-link></li>
    </ul>-->

  </div>
</template>

<script>
// @ is an alias to /src

import ComingSoonBanner from "@/components/ComingSoonBanner";

export default {
  name: 'Benchmarks',
  components: {
    ComingSoonBanner
  }
}
</script>
